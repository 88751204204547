import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Support from "./pages/aml-support";
import Index from "./pages";
import Service1 from "./pages/annual-renewal-services (1)";
import AccountingTaxation from "./pages/accounting-&-taxation";
import Service from "./pages/annual-renewal-services";
import WhoWeAre from "./pages/who-we-are";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import AuditSupport from "./pages/audit-&-audit-support";
import AccountOpening from "./pages/bank-acccount-opening";
import Blog from "./pages/blog";
import BookKeeping from "./pages/bookkeeping (2)";
import BusinessPlanning from "./pages/business-planning";
import BusinessSetup from "./pages/business-setup";
import BusinessValuation from "./pages/business-valuation";
import CompanyFormation from "./pages/cayman-company-formation";
import Contact from "./pages/contact";
import CorporateGov from "./pages/corporate-governance";
import Deligence from "./pages/due-deligence";
import EcommerceBusiness from "./pages/e-commerce-business-formation";
import EcommerceSubstance from "./pages/economic-substance-regulation";
import EstoniaCompany from "./pages/estonia-company-formation";
import FinancialStatement from "./pages/financial-statement-reporting";
import Advisory from "./pages/ifrs-advisory";
import InternalControl from "./pages/internal-control";
import ManagementAccounting from "./pages/management-accounting-&-kpis";
import OfficeSpace from "./pages/office-space-provision";
import Office from "./pages/our-offices";
import Team from "./pages/our-team";
import Payroll from "./pages/people-&-payroll";
import Quote from "./pages/quote";
import ResidencyService from "./pages/residency-services";
import SupplyChain from "./pages/supply-chain";
import TaxConsultancy from "./pages/tax-consultancy";
import TransferPricing from "./pages/transfer-pricing";
import UaeCompany from "./pages/uae-company-formation";
import UkCompany from "./pages/uk-company-formation";
import BeneficialOwner from "./pages/ultimate-beneficial-owner";
import UsaCompany from "./pages/usa-company-formation";
import BlogPost from "./pages/BlogPost/BlogPost";
import Footer from "./components/Footer/Footer";
import Navmenu from "./pages/navmenu";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/About" element={<WhoWeAre />} />
        <Route path="/Who-we-are" element={<WhoWeAre />} />
        <Route path="/Account-taxation" element={<AccountingTaxation />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/Service1" element={<Service1 />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Audit-support" element={<AuditSupport />} />
        <Route path="/Account-opening" element={<AccountOpening />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Book-keeping" element={<BookKeeping />} />
        <Route path="/Business-planning" element={<BusinessPlanning />} />
        <Route path="/business-setup" element={<BusinessSetup />} />
        <Route path="/Business-valuation" element={<BusinessValuation />} />
        <Route path="/Company-formation" element={<CompanyFormation />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Corporate-government" element={<CorporateGov />} />
        <Route path="/Deligence" element={<Deligence />} />
        <Route path="/Ecommerce-business" element={<EcommerceBusiness />} />
        <Route path="/Ecommerce-substance" element={<EcommerceSubstance />} />
        <Route path="/Estonia-company" element={<EstoniaCompany />} />
        <Route path="/Financial-statement" element={<FinancialStatement />} />
        <Route path="/Advisory" element={<Advisory />} />
        <Route path="/Internal-control" element={<InternalControl />} />
        <Route
          path="/Management-Accounting"
          element={<ManagementAccounting />}
        />
        <Route path="/Office-space" element={<OfficeSpace />} />
        <Route path="/Office" element={<Office />} />
        <Route path="/our-team" element={<Team />} />
        <Route path="/Payroll" element={<Payroll />} />
        <Route path="/Quote" element={<Quote />} />
        <Route path="/Residency-service" element={<ResidencyService />} />
        <Route path="/Supply-chain" element={<SupplyChain />} />
        <Route path="/Tax-consultancy" element={<TaxConsultancy />} />
        <Route path="/TransferPricing" element={<TransferPricing />} />
        <Route path="/Uae-company" element={<UaeCompany />} />
        <Route path="/Uk-company" element={<UkCompany />} />
        <Route path="/Beneficial-owner" element={<BeneficialOwner />} />
        <Route path="/Usa-company" element={<UsaCompany />} />
        <Route path="/Cayman-company" element={<CompanyFormation />} />
        <Route
          path="/Blog-page"
          element={
            <>
              <Navmenu />
              <BlogPost />
            </>
          }
        />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
