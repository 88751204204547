import { Fragment } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

function Navmenu() {
  return (
    <Fragment>
      <div>
        <div className="container-fluid bg-dark px-5 d-none d-lg-block">
          <div className="row gx-0">
            <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
              <div
                className="d-inline-flex align-items-center"
                style={{ height: "45px" }}
              >
                <small className="me-3 text-light">
                  <i className="fa fa-map-marker-alt me-2" />
                  Dubai-UAE, London-UK, Delaware-USA
                </small>
                <small className="me-3 text-light">
                  <i className="fa fa-phone-alt me-2" />
                  +971502923136
                </small>
                <small className="text-light">
                  <i className="fa fa-envelope-open me-2" />
                  info@ogmholding.com
                </small>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <div
                className="d-inline-flex align-items-center"
                style={{ height: "45px" }}
              >
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://www.facebook.com/profile.php?id=100091747087457&mibextid=LQQJ4d"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </a>
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://www.linkedin.com/company/o-g-m-holding-co-ltd/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </a>
                <a
                  className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="https://www.instagram.com/ogmconsultants_?igsh=OGo5ZjkxaGRoODQz&utm_source=qr"
                  target="_blank"
                >
                  <i className="fab fa-instagram fw-normal" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* <Link to='/'>Home</Link> */}

        {/* Navbar & Carousel Start */}
        <div className="container-fluid position-relative p-0 ">
          <nav className="navbar navbar-expand-lg navbar-dark px-sm-2 px-lg-5 py-3 py-lg-0">
            <div className="ms-2">
              <a className="navbar-brand" href="/">
                <img src="assets/img/logo.png" alt="" width={170} height={50} />
              </a>
            </div>
            <button
              className="navbar-toggler me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto py-0">
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                <Nav className="navbar-nav">
                  <Nav.Link href="/">Home</Nav.Link>
                  <NavDropdown title="About Us">
                    <NavDropdown.Item href="Who-we-are">
                      Who we Are
                    </NavDropdown.Item>
                    <NavDropdown.Item href="Office">
                      Our Offices
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Our Services">
                    <NavDropdown title=" Business Set up »">
                      <NavDropdown.Item href="Uae-company">
                        UAE Company Formation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Usa-company">
                        USA Company Formation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Uk-company">
                        UK Company Formation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Cayman-company">
                        Cayman Company Formation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Estonia-company">
                        Estonia Company Formation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Ecommerce-business">
                        E-commerce Business Formation
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Accounting & taxation »">
                      <NavDropdown.Item href="Book-keeping">
                        Bookkeeping
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Management-accounting">
                        {" "}
                        Management Accounting &amp; KPIs
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Business-planning">
                        Business planning
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Tax-consultancy">
                        Tax Consultancy
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Business-valuation">
                        Business Valuation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="TransferPricing">
                        Transfer pricing{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Supply-chain">
                        Supply Chain
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Statutory Compliance »">
                      <NavDropdown.Item href="Corporate-government">
                        Corporate Governance
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Internal-control">
                        {" "}
                        Internal Control{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Audit-support">
                        Audit &amp; Audit Support
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Financial-statement">
                        {" "}
                        Financial Statement Reporting
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Advisory">
                        IFRS Advisory
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Ecommerce-substance">
                        Economic Substance Regulation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Beneficial-owner">
                        {" "}
                        Ultimate Beneficial Owner
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Support">
                        AML Support
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Deligence">
                        {" "}
                        Due Deligence{" "}
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Support »">
                      <NavDropdown.Item href="Account-opening">
                        Bank Account Opening
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Payroll">
                        People &amp; Payrolls{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Service">
                        Annual Renewal Services
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Residency-service">
                        Residency Services
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Office-space">
                        Office Space Provision
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Ecommerce-substance">
                        Economic Substance Regulation
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Beneficial-owner">
                        Ultimate Beneficial Owner
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Support">
                        AML Support
                      </NavDropdown.Item>
                      <NavDropdown.Item href="Deligence">
                        Due Deligence
                      </NavDropdown.Item>
                    </NavDropdown>
                  </NavDropdown>{" "}
                  <Nav.Link href="Blog">Blog</Nav.Link>
                  <Nav.Link href="Contact">Contact</Nav.Link>
                </Nav>
              </div>
              <button
                type="button"
                className="btn text-primary ms-3"
                data-bs-toggle="modal"
                data-bs-target="#searchModal"
              >
                <i className="fa fa-search" />
              </button>
              {/* <a href="Contact" className="btn btn-primary py-2 px-4 ms-3">
                Sign Up
              </a> */}
            </div>
          </nav>
        </div>
      </div>
    </Fragment>
  );
}
export default Navmenu;
